/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'assets/scss/fonts';
@import 'assets/scss/variables';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import 'ngx-toastr/toastr';

// Global variables
:root {
    --font-family: 'Mulish', 'Segoe UI', 'Helvetica', sans-serif;
    --color-background: #f9f9f9;
    --color-primary: var(--color-violet-red-500);
    --color-accent: var(--color-dark-blue-500);
    --color-text: var(--color-grey-800);
    --color-text-light: var(--color-grey-400);
    --color-red: #d71947;
    --color-violet-red-500: #d71966;
    --color-dark-blue-50: #f1eff9;
    --color-dark-blue-500: #1e3ca5;
    --color-dark-blue-200: #c5c3e6;
    --color-white: #ffffff;

    --shadow-s: 0px 1px 2px rgba(0, 0, 0, 0.05);
    --shadow-m: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1);
    --border-radius: 6px;

    --color-grey-100: #e7e7e7;
    --color-grey-200: #cecece;
    --color-grey-400: #848484;
    --color-grey-700: #1b1b1b;
    --color-grey-800: #070707;
}

.left-arrow {
    position: absolute;
    left: 25.5px;
    top: 22px;
}

.max-width500 {
    max-width: 500px;
}


#login-section .pink-button, #register-section .pink-button, .pink-button {
    width: 342px;
    background-color: var(--color-red);
    border: 0;
    border-radius: 6px;
    height: 48px;
    font-size: 18px;
    color: #ffffff;
    margin-top: 20px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
}

.full-height {
    min-height: 100vh !important;
}

.with-topbar {
    padding-top: 56px;
}

.z-index-99 {
    z-index: 99;
}

.clickable {
    cursor: pointer;
}

.d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.d-flex-column-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.color-red {
    color: $red-color;
}

.text-underline {
    text-decoration: underline;
}

.text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.cursor-pointer {
    cursor: pointer;
    outline: none;
}

.background-grey {
    background-color: var(--color-background);
}

.background-purple {
    background-color: var(--color-dark-blue-200);
}

button:focus {
    border: none !important;
    outline: none !important;
}

.password-icon {
    margin-top: 16px;
    margin-left: -35px;
    opacity: 0.7;
}

.input,
.textarea {
    box-sizing: border-box;
    height: 56px;
    border: 1px solid #dbe0eb;
    border-radius: 6px;
    background-color: white;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: 25px;

    width: 100%;
    padding: 1rem 1.5rem;
}

.textarea {
    height: 100% !important;
}

.input:focus,
.textarea:focus {
    outline-color: $grey-color;
}

@mixin normal-btn($font-color: white, $bg-color: $red-color, $border-color: $red-color) {
    box-sizing: border-box;
    min-height: 50px;
    border-radius: 6px;
    font-family: nunito-bold, Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 1.25px;
    line-height: 22px;
    border: 1px solid $border-color;
    background-color: $bg-color;
    color: $font-color;
}

.white-btn {
    @include normal-btn($dark-blue-color, white, $dark-blue-color);
}

.red-btn {
    @include normal-btn();
}

button.red-btn:disabled {
    background-color: white !important;
    color: $red-color !important;
    border-color: $red-color !important;
}

//TODO: Working only with angular material?
.is-button-loading {
    color: transparent !important;
    pointer-events: none;

    &:before {
        box-sizing: border-box;
        position: absolute;
        content: '';

        border-radius: 50%;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        margin-top: -10px;
        margin-left: -10px;

        border: 2px solid white;
        border-right: 2px solid transparent;

        animation: rotate-button 0.5s linear infinite;
    }
}

@keyframes rotate-button {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
