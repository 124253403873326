/* Global fonts */
@font-face {
  font-family: nunito-regular;
  src: url(../font/NunitoSans-Regular.ttf);
}

@font-face {
  font-family: nunito-bold;
  src: url(../font/NunitoSans-Bold.ttf);
}

@font-face {
  font-family: nunito-semibold;
  src: url(../font/NunitoSans-SemiBold.ttf);
}

@font-face {
  font-family: athelas;
  src: url(../font/Athelas-Regular.woff);
}
